<template>
  <div class="project px-5">
    <div class="body-2 grey--text text--darken-2">Проекты > {{ project.title }}</div>
    <h2 class="font-weight-medium mt-1">{{ project.title }}</h2>
    <p class="caption">{{ project.description }}</p>
    <v-divider />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters('project', ['project'])
  },
  mounted() {
    this.fetchProject(this.projectId)
  },
  methods: {
    ...mapActions('project', ['fetchProject'])
  }
}
</script>
